.bottom-right-buttton{
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.instaaa{
    position: fixed;
    bottom: 20px;
    right: 40px;
    width: 50px;
    height: 50px;
    color:rgb(255, 0, 212);
    fill: rgb(255, 0, 212);
    cursor:pointer;
}