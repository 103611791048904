.mainService{
    padding: 2%;
    position: relative;
    padding-top: 4rem;
    font-family: 'Quicksand', sans-serif;
  
}
.slider-container{
    display: flex;

    place-items: center center;
    grid-template-columns: 1fr;
    padding: 20px;
    margin-bottom: 20px;
    grid-gap: 20px;
}
.slider{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
    transition: transform 0.5s ease;
}
.cards{
    display: grid;

    place-items: center center;
    grid-template-columns: repeat(auto-fit, 1fr);
    padding: 20px;
    margin-bottom: 20px;
    grid-gap: 20px;
}
.controls{
    background: linear-gradient(black,rgb(162, 158, 158)
    ) }

@media screen and (max-width: 700px) {
    .mainService{
        display: flex;
        flex-direction: column;
    }
}

.outer-grid {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr; /* Three columns in the outer grid */
    gap: 5px; /* Gap between columns */
  }

  /* Define the inner grid */
  .inner-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* four columns in the inner grid */
    gap: 5px; /* Gap between columns in the inner grid */
  }

  /* Style for the grid items (just for demonstration purposes) */
  .grid-item {
    /*border: 1px solid #333; */
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .gridClass{
    margin-left: 10%;
    margin-right: 10%;
  }
.carousel{
  align-items: center;
  height: 500px;
  justify-content: center;
  
    
}
.carousel:first-child{
 margin-left: 50px;
 margin-left: 100px;
}
.slider{
  margin-left: 20px;
  margin-right: 20px;

}
.projectItem{
  height: 200px;
  width: 200px;
  justify-content: center;
  align-items: center;
  display: flex;

}

.sub-headings{
  margin: 10px 10px 20px 0px;
  text-align: start;
}

.sc-dnqmqq bVoXXg{
  align-items: center;
  justify-content: center;
}

.carr{
 opacity: 1;
}

.cardele{
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 1.5rem;
}