.App {
  text-align: start;
  color: rgb(255, 253, 248);
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face{
  font-family:  'Quicksand', sans-serif;
}


*{
  font-family:  'Quicksand', sans-serif;
  font-weight:normal;
  font-size: 20px;
  text-align: center;
}
body{margin: 0%;}


body {
  background-color: #fafafa;
  color: #162450;
}
body.dark {
  background-color: #162450;
  color: #efefef;
}