.mainHome {
  padding-top: 4rem;
  height: 500px;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'Quicksand', sans-serif;
  font-size: 17px;
}

.homeHeading{
  display: flex;
  font-family:  'Quicksand', sans-serif;
  font-size: xx-large
  
}

.image{
  width: 30%
}
.gridClass {
  height: 100%;
}
.outer-grid-home {
  display: grid;
  grid-template-columns: 3fr auto; /* Three columns in the outer grid */
  gap: 5px; /* Gap between columns */

  font-family:  'Quicksand', sans-serif;
}
.grid-item-home{
    font-family:  'Quicksand', sans-serif;
    font-size: xx-large;
    text-align: start;

}
.aboutIntro{
  font-size: 5px;
  text-align: start;
}
.image2{
  width: 50%;
  height: 50%;
}
.outer-grid-cv-button{
  gap: 5px;
}
/* Define the inner grid */


@media screen and (max-width: 600px) {
  .gridClass {
    font-size: 10px;
  }
  .aboutIntro{
    display:none;
  }
}

@media screen and (max-width: 800px) {
  .gridClass {
    font-size: 20px;
  }
  
  .aboutIntro{
    display:none;
  }
}
.downloadCV{
    font-family: 'Quicksand', sans-serif;
    font-size: large;
    font-weight: bold;
    border-radius: 15%;
    border-width: 2px;
    border-color: #5594fa;
    padding: 5%;
    
}
.aboutIntro{
  
  font-family: 'Quicksand', sans-serif;
  font-size: medium;
  padding: 0;
  margin: 0;
}